import { datadogRum } from "@datadog/browser-rum";

export function initDataDog() {
  datadogRum.init({
    applicationId: "1cce7907-f0e3-495f-9803-69bfa6dd0ff6",
    clientToken: "pub7b0ebe5752ca029e66a9e1692ec41384",
    site: "datadoghq.com",
    env: ENV.NODE_ENV,
    service: "buyer-portal-prod",
    version: ENV.VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}
