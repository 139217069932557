import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { isDatadogEnv } from "./common/Config";
import reportWebVitals from "./reportWebVitals";

import { datadogRum } from "@datadog/browser-rum";
import { initDataDog } from "./common/utils/thirdParty/DataDog/DataDog";

function onRecoverableError(error: unknown) {
  datadogRum.addError(error, { recoverableError: true });
}

startTransition(() => {
  hydrateRoot(
    document.getElementById("root")!,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
    { onRecoverableError }
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (isDatadogEnv) {
  initDataDog();
}
